// This file was generated by lezer-generator. You probably shouldn't edit it.
// (It was then subsequently modified by generate-lezer-parsers).
import {LRParser} from "@lezer/lr"
import {specializeKeywordGen, extendKeywordGen} from "./tokens"
export function generateParser(level: number, language: string): LRParser {
  const specializeKeyword = specializeKeywordGen(level, language);
  const extendKeyword = extendKeywordGen(level, language);
  return LRParser.deserialize({
  version: 14,
  states: "2SQYQPOOOOQO'#Df'#DfQYQPOOO!WQPO'#DlO!iQPO'#DeOOQO'#Dp'#DpO!tQPO'#DSO#PQPO'#DTOOQO'#Dq'#DqO#[QPO'#DUOOQO'#Dr'#DrO#gQPO'#DWOOQO'#Ds'#DsO#rQPO'#DXOOQO'#Dt'#DtO#}QPO'#DYOOQO'#DV'#DVOOQO'#Du'#DuO$YQPO'#DZOOQO'#Dv'#DvO$kQPO'#D[OOQO'#Dx'#DxO$sQPO'#D]OOQO'#Dz'#DzO${QPO'#D^OOQO'#EO'#EOO%WQPO'#DcOOQO'#EP'#EPO%cQPO'#DdOOQO'#Cu'#CuQ!RQPO'#DgQ%nQPOOOOQO-E7d-E7dOOQO'#Dh'#DhO&iQPO,59bOOQO'#Cx'#CxO&wQPO,59bOOQO'#Dl'#DlOOQO-E7j-E7jOOQO-E7n-E7nO'SQPO'#DoOOQO'#Cy'#CyO(`QPO'#CyOOQO'#Do'#DoO(gQPO,59oO(xQPO,59pOOQO-E7o-E7oOOQO,59p,59pO)^QPO,59rOOQO-E7p-E7pOOQO,59r,59rO)rQPO,59sOOQO-E7q-E7qOOQO,59s,59sO*WQPO,59tOOQO-E7r-E7rOOQO,59t,59tO*lQPO,59uOOQO-E7s-E7sOOQO,59u,59uO+QQPO,59vOOQO-E7t-E7tO+YQPO,59wOOQO-E7v-E7vOOQO-E7x-E7xO+bQPO'#D`OOQO'#D_'#D_OOQO,59x,59xOOQO-E7|-E7|OOQO-E7}-E7}O+pQPO,5:OO+uQPO,5:ROOQO-E7e-E7eOOQO-E7f-E7fO,vQPO'#DiO-bQPO1G/UO-rQPO'#CyOOQO'#Di'#DiO.VQPO1G.|O.eQPO1G/UOOQO'#Dn'#DnO.vQPO1G/WOOQO'#C{'#C{OOQO'#C|'#C|O/UQPO,5:rO/UQPO,5:rOOQO'#Dj'#DjO/^QPO,59iOOQO-E7m-E7mOOQO'#Dw'#DwO/lQPO1G/bOOQO'#Dy'#DyO/tQPO1G/cO/|QPO,59zO0_QPO,59zOOQO'#D|'#D|O0mQPO,59{OOQO'#D}'#D}O0uQPO,59|OOQO'#EQ'#EQO0}QPO1G/jOOQO'#DP'#DPO1YQPO'#DmO1bQPO7+$pOOQO-E7g-E7gOOQO-E7l-E7lO1vQPO7+$rOOQO1G0^1G0^O2XQPO1G0^OOQO-E7h-E7hOOQO'#Dk'#DkO2vQPO1G/TOOQO1G/T1G/TOOQO-E7u-E7uOOQO7+$|7+$|OOQO-E7w-E7wOOQO7+$}7+$}O3[QPO1G/fOOQO'#D{'#D{O3pQPO1G/fOOQO1G/f1G/fOOQO-E7z-E7zOOQO1G/g1G/gOOQO-E7{-E7{OOQO1G/h1G/hOOQO-E8O-E8OO3{QPO,5:XOOQO,5:X,5:XOOQO-E7k-E7kOOQO-E7i-E7iOOQO-E7y-E7y",
  stateData: "4g~OgOS!wOS~OSgOUiOVVOWYOX[OY^OZaO[WO^cO`eObTOekOkRO!xPO~O]qO!ysOk!`X!u!`X!x!`X~OkuO!u!XX!x!XX~ObTO!uvX!xvX~OkzOnyOu{O~O[WOkzOnyO~OWYOkzOnyO~OX[OkzOnyO~OY^OkzOnyO~OZaOkzOnyO!u}X!x}X~O^cOkuO~O`eOkuO~OSgOk!cOn!cO~OUiO!u!VX!x!VX~OekOk!hOn!hO~OSgOUiOVVOWYOX[OY^OZaO[WO^cO`eObTOekOkRO~OP!rO]qOk!nOn!mO~OP!rOk!nOn!mO~O!{!tO!|!tO!}!uO#O!uOk!cXn!cXu!cX!u!cX!x!cX~OQ!xOkmXnmX!umX!xmX!{mX!|mX!}mX#OmX~OumX~P'qOkzOnyOu{O!uwa!xwa~O!{!tO!|!tO!}!uO#O!uO!uxa!xxa~O!{!tO!|!tO!}!uO#O!uO!uza!xza~O!{!tO!|!tO!}!uO#O!uO!u{a!x{a~O!{!tO!|!tO!}!uO#O!uO!u|a!x|a~O!{!tO!|!tO!}!uO#O!uO!u}a!x}a~Oa!{OkuO~O_!}OkuO~O]qOc#ROd#TO!ysO~Of#VO~O!xPOS!ZaU!ZaV!ZaW!ZaX!ZaY!ZaZ!Za[!Za^!Za`!Zab!Zae!Zak!Za!u!Za~O!{!tO!|!tO!}!uO#O!uOk!]Xn!]X!u!]X!x!]X~O#P#XO#Q#XO#R#XO#S#XO~P'tOk!`X#P!`X#Q!`X#R!`X#S!`X~P'qOkzOnyO!uji!xji~OkuO#P#XO#Q#XO#R#XO#S#XO~OP!rOkzOnyOu{O~OkyOnyO~OQ!xOR#bOk#dOn#dO~Oa!{Ok#fO~O_!}Ok#hO~OT#jO]qOkyOnyOu#lO~OT#jOkyOnyOu#lO~Oc#ROk#nO~Od#TOk#pO~Of#VO!u!Wi!x!Wi~OkuOn#sO~O#P#XO#Q#XO#R#XO#S#XO!urq!xrq~OkzOnyOu{O!utq!xtq~O!{!tO!|!tO!}!uO#O!uOk!zin!ziu!zi!u!zi!x!zi~OR#bOkqinqiuqi!uqi!xqi~O!{!tO!|!tO!}!uO#O!uO!u!Si!x!Si~OT#jO!u!Si!x!Si~OkuO!u!aa!x!aa#P!aa#Q!aa#R!aa#S!aa~Onk~",
  goto: "*o!{PPPPPPPPPPPPPPPPPPPPPPPPP!|#RP#W#^P$S$_$j#R%U#RP#R#R#R#R%Z%Z%Z#R#R#R#R%`%c%c%c#R#R#R%f%p%w&R&Y&a&g'Q'X'`'j'r'z(S([(d(l(t(z)S)Y)b)i)o)u)}*VPPPPP*]VnOQoVmOQoQtRR#Q!cWxV|!s#^Q}XQ!QZQ!T]Q!W_Q!ZbU!lrt!pQ#_!vQ#`!wT#i#P#Qc!vx}!Q!T!W!Z!l#`#ic!wx}!Q!T!W!Z!l#`#iW{V|!s#^Q!PXQ!SZQ!V]Q!Y_Q!]bV!ort!pV#Y!m!q#ZV`OQoR!ehR!dhQQOSpQ!iR!inSoOQR!joQrRS!kr#PR#P!cS!prtR#[!pS!yz!nR#a!yQ#c!yR#u#cUSOQoYvS!^!`!q#rQ!^dQ!`fS!qrtR#r#YS#Z!m!qR#t#ZS!srtR#]!sQ|VS!z|#^R#^!sUUOQoRwUUXOQoR!OXUZOQoR!RZU]OQoR!U]U_OQoR!X_UbOQoR![bUdOQoR!_dQ!|!^R#e!|UfOQoR!afQ#O!`R#g#OUhOQoR!bhS#k#P#QR#v#kQ#S!cR#m#SQ#U!cR#o#UUjOQoR!fjUlOQoR!glQ#W!hR#q#WqyVXZ]_brt|!p!s!v!w#P#Q#^",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep play is add from remove to_list clear in not_in repeat times Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Op Ask String Clear Print Play Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck Else Repeat ErrorInvalid",
  maxTerm: 96,
  nodeProps: [
    ["group", 41,"turtle"]
  ],
  skippedNodes: [0,23],
  repeatNodeCount: 27,
  tokenData: "1l~R!nYZ&Ppq&Uqr&Zrs*hst+]tu&Zuv&Zvw&Zwx+txy&Zyz&Zz{,d{|,i|},n}!O,s!O!P&Z!P!Q,x!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!_!`1W!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBnBo1]BoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FV&FW1b&FW;'S&Z;'S;=`*b<%l?Hb&Z?Hb?Hc1g?HcO&Z~&UO!x~~&ZO!w~~&`!`k~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R&Z!R!S&Z!S!T&Z!T!U&Z!U!V&Z!V!W&Z!W!X&Z!X!Y&Z!Y!Z&Z!Z![&Z![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg&ZDgDh&ZDhDi&ZDiDj&ZDjDk&ZDkDl&ZDlDm&ZDmDn&ZDnDo&ZDoDp&ZDvGl&ZGlGm&ZGmGn&ZGnGo&ZGoGp&ZGpGq&ZGqGr&ZGrGs&ZGsGt&ZGtGu&ZGuGv&ZGv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~*eP;=`<%l&Z~*kVOY*hZr*hrs+Qs#O*h#P;'S*h;'S;=`+V<%lO*h~+VOu~~+YP;=`<%l*h~+bSg~OY+]Z;'S+];'S;=`+n<%lO+]~+qP;=`<%l+]~+wVOY+tZw+twx+Qx#O+t#P;'S+t;'S;=`,^<%lO+t~,aP;=`<%l+t~,iO!{~~,nO!}~~,sO#P~~,xO#O~~,}O!|~~-U!`n~k~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~1]O!y~~1bO#Q~~1gO#S~~1lO#R~",
  tokenizers: [0],
  topRules: {"Program":[0,24]},
  dynamicPrecedences: {"55":-10},
  specialized: [{term: 27, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 27, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 894
})
}
