// This file was generated by lezer-generator. You probably shouldn't edit it.
// (It was then subsequently modified by generate-lezer-parsers).
import {LRParser} from "@lezer/lr"
import {specializeKeywordGen, extendKeywordGen} from "./tokens"
export function generateParser(level: number, language: string): LRParser {
  const specializeKeyword = specializeKeywordGen(level, language);
  const extendKeyword = extendKeywordGen(level, language);
  return LRParser.deserialize({
  version: 14,
  states: "2xQYQPOOOOQO'#Dh'#DhQYQPOOO!ZQPO'#DnO!lQPO'#DgOOQO'#Dr'#DrO!wQPO'#DTO#SQPO'#DUOOQO'#Ds'#DsO#_QPO'#DVOOQO'#Dt'#DtO#jQPO'#DXOOQO'#Du'#DuO#uQPO'#DYOOQO'#Dv'#DvO$QQPO'#DZOOQO'#DW'#DWOOQO'#Dw'#DwO$]QPO'#D[OOQO'#Dx'#DxO$nQPO'#D]OOQO'#Dz'#DzO$vQPO'#D^OOQO'#D|'#D|O%OQPO'#D_OOQO'#EQ'#EQO%ZQPO'#DdOOQO'#ER'#ERO%fQPO'#DeOOQO'#ET'#ETO%qQPO'#DfOOQO'#Cv'#CvQ!UQPO'#DiQ%yQPOOOOQO-E7f-E7fOOQO'#Dj'#DjO&wQPO,59cOOQO'#Cy'#CyO'VQPO,59cOOQO'#Dn'#DnOOQO-E7l-E7lOOQO-E7p-E7pO'bQPO'#DqOOQO'#Cz'#CzO(nQPO'#CzOOQO'#Dq'#DqO(uQPO,59pO)WQPO,59qOOQO-E7q-E7qOOQO,59q,59qO)lQPO,59sOOQO-E7r-E7rOOQO,59s,59sO*QQPO,59tOOQO-E7s-E7sOOQO,59t,59tO*fQPO,59uOOQO-E7t-E7tOOQO,59u,59uO*zQPO,59vOOQO-E7u-E7uOOQO,59v,59vO+`QPO,59wOOQO-E7v-E7vO+hQPO,59xOOQO-E7x-E7xOOQO-E7z-E7zO+pQPO'#DaOOQO'#D`'#D`OOQO,59y,59yOOQO-E8O-E8OOOQO-E8P-E8PO,OQPO,5:POOQO-E8R-E8RO,TQPO,5:QO,YQPO,5:TOOQO-E7g-E7gOOQO-E7h-E7hO-^QPO'#DkO-xQPO1G/VO.YQPO'#CzOOQO'#Dk'#DkO.mQPO1G.}O.{QPO1G/VOOQO'#Dp'#DpO/^QPO1G/XOOQO'#C|'#C|OOQO'#C}'#C}O/lQPO,5:uO/lQPO,5:uOOQO'#Dl'#DlO/tQPO,59jOOQO-E7o-E7oOOQO'#Dy'#DyO0SQPO1G/cOOQO'#D{'#D{O0[QPO1G/dO0dQPO,59{O0uQPO,59{OOQO'#EO'#EOO1TQPO,59|OOQO'#EP'#EPO1]QPO,59}OOQO'#ES'#ESO1eQPO1G/kO1pQPO1G/lOOQO'#DQ'#DQO1xQPO'#DoO2QQPO7+$qOOQO-E7i-E7iOOQO-E7n-E7nO2fQPO7+$sOOQO1G0a1G0aO2wQPO1G0aOOQO-E7j-E7jOOQO'#Dm'#DmO3fQPO1G/UOOQO1G/U1G/UOOQO-E7w-E7wOOQO7+$}7+$}OOQO-E7y-E7yOOQO7+%O7+%OO3zQPO1G/gOOQO'#D}'#D}O4`QPO1G/gOOQO1G/g1G/gOOQO-E7|-E7|OOQO1G/h1G/hOOQO-E7}-E7}OOQO1G/i1G/iOOQO-E8Q-E8QOOQO7+%W7+%WO4kQPO,5:ZOOQO,5:Z,5:ZOOQO-E7m-E7mOOQO-E7k-E7kOOQO-E7{-E7{",
  stateData: "5V~OhOS!zOS~OSgOUiOVVOWYOX[OY^OZaO[WO^cO`eObTOekOgmOlRO!{PO~O]sO!|uOl!bX!x!bX!{!bX~OlwO!x!ZX!{!ZX~ObTO!xwX!{wX~Ol|Oo{Ov}O~O[WOl|Oo{O~OWYOl|Oo{O~OX[Ol|Oo{O~OY^Ol|Oo{O~OZaOl|Oo{O!x!OX!{!OX~O^cOlwO~O`eOlwO~OSgOl!eOo!eO~OUiO!x!WX!{!WX~OekOl!jOo!jO~OgmOl!lO~OSgOUiOVVOWYOX[OY^OZaO[WO^cO`eObTOekOgmOlRO~OP!vO]sOl!rOo!qO~OP!vOl!rOo!qO~O#O!xO#P!xO#Q!yO#R!yOl!eXo!eXv!eX!x!eX!{!eX~OQ!|OlnXonX!xnX!{nX#OnX#PnX#QnX#RnX~OvnX~P(POl|Oo{Ov}O!xxa!{xa~O#O!xO#P!xO#Q!yO#R!yO!xya!{ya~O#O!xO#P!xO#Q!yO#R!yO!x{a!{{a~O#O!xO#P!xO#Q!yO#R!yO!x|a!{|a~O#O!xO#P!xO#Q!yO#R!yO!x}a!{}a~O#O!xO#P!xO#Q!yO#R!yO!x!Oa!{!Oa~Oa#POlwO~O_#ROlwO~O]sOc#VOd#XO!|uO~Of#ZO~Oc#VO~O!{POS!]aU!]aV!]aW!]aX!]aY!]aZ!]a[!]a^!]a`!]ab!]ae!]ag!]al!]a!x!]a~O#O!xO#P!xO#Q!yO#R!yOl!_Xo!_X!x!_X!{!_X~O#S#^O#T#^O#U#^O#V#^O~P(SOl!bX#S!bX#T!bX#U!bX#V!bX~P(POl|Oo{O!xki!{ki~OlwO#S#^O#T#^O#U#^O#V#^O~OP!vOl|Oo{Ov}O~Ol{Oo{O~OQ!|OR#gOl#iOo#iO~Oa#POl#kO~O_#ROl#mO~OT#oO]sOl{Oo{Ov#qO~OT#oOl{Oo{Ov#qO~Oc#VOl#sO~Od#XOl#uO~Of#ZO!x!Xi!{!Xi~Oc#VOl#wO~OlwOo#yO~O#S#^O#T#^O#U#^O#V#^O!xsq!{sq~Ol|Oo{Ov}O!xuq!{uq~O#O!xO#P!xO#Q!yO#R!yOl!}io!}iv!}i!x!}i!{!}i~OR#gOlriorivri!xri!{ri~O#O!xO#P!xO#Q!yO#R!yO!x!Ti!{!Ti~OT#oO!x!Ti!{!Ti~OlwO!x!ca!{!ca#S!ca#T!ca#U!ca#V!ca~Ool~",
  goto: "+O#OPPPPPPPPPPPPPPPPPPPPPPPPPP#P#UP#Z#aP$V$b$m#U%X#UP#U#U#U#U%^%^%^#U#U#U#U%c%f%f%f#U#U#U#U%i%s%z&U&]&d&j'T'['c'm'u'}(V(_(g(o(w(})V)])e)l)v)|*U*^*dPPPPP*lVpOQqVoOQqQvRR#U!eWzV!O!w#cQ!PXQ!SZQ!V]Q!Y_Q!]bU!ptv!tQ#d!zQ#e!{T#n#T#Uc!zz!P!S!V!Y!]!p#e#nc!{z!P!S!V!Y!]!p#e#nW}V!O!w#cQ!RXQ!UZQ!X]Q![_Q!_bV!stv!tV#_!q!u#`V`OQqR!ghR!fhQQOSrQ!mR!mpSqOQR!nqQtRS!ot#TR#T!eS!ttvR#a!tS!}|!rR#f!}Q#h!}R#{#hUSOQqYxS!`!b!u#xQ!`dQ!bfS!utvR#x#_S#`!q!uR#z#`S!wtvR#b!wQ!OVS#O!O#cR#c!wUUOQqRyUUXOQqR!QXUZOQqR!TZU]OQqR!W]U_OQqR!Z_UbOQqR!^bUdOQqR!adQ#Q!`R#j#QUfOQqR!cfQ#S!bR#l#SUhOQqR!dhS#p#T#UR#|#pQ#W!eQ#]!lT#r#W#]Q#Y!eR#t#YUjOQqR!hjUlOQqR!ilQ#[!jR#v#[UnOQqR!knq{VXZ]_btv!O!t!w!z!{#T#U#c",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep play is add from remove to_list clear in not_in repeat times for Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Op Ask String Clear Print Play Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck Else Repeat For ErrorInvalid",
  maxTerm: 99,
  nodeProps: [
    ["group", 42,"turtle"]
  ],
  skippedNodes: [0,24],
  repeatNodeCount: 28,
  tokenData: "1l~R!nYZ&Ppq&Uqr&Zrs*hst+]tu&Zuv&Zvw&Zwx+txy&Zyz&Zz{,d{|,i|},n}!O,s!O!P&Z!P!Q,x!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!_!`1W!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBnBo1]BoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FV&FW1b&FW;'S&Z;'S;=`*b<%l?Hb&Z?Hb?Hc1g?HcO&Z~&UO!{~~&ZO!z~~&`!`l~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R&Z!R!S&Z!S!T&Z!T!U&Z!U!V&Z!V!W&Z!W!X&Z!X!Y&Z!Y!Z&Z!Z![&Z![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg&ZDgDh&ZDhDi&ZDiDj&ZDjDk&ZDkDl&ZDlDm&ZDmDn&ZDnDo&ZDoDp&ZDvGl&ZGlGm&ZGmGn&ZGnGo&ZGoGp&ZGpGq&ZGqGr&ZGrGs&ZGsGt&ZGtGu&ZGuGv&ZGv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~*eP;=`<%l&Z~*kVOY*hZr*hrs+Qs#O*h#P;'S*h;'S;=`+V<%lO*h~+VOv~~+YP;=`<%l*h~+bSh~OY+]Z;'S+];'S;=`+n<%lO+]~+qP;=`<%l+]~+wVOY+tZw+twx+Qx#O+t#P;'S+t;'S;=`,^<%lO+t~,aP;=`<%l+t~,iO#O~~,nO#Q~~,sO#S~~,xO#R~~,}O#P~~-U!`o~l~qr&Ztu&Zuv&Zvw&Zxy&Zyz&Z!O!P&Z!Q!R,}!R!S,}!S!T,}!T!U,}!U!V,}!V!W,}!W!X,}!X!Y,}!Y!Z,}!Z![,}![!]&Z!]!^&Z!^!_&Z!`!a&Z!a!b&Z!b!c&Z!c!}&Z!}#O&Z#O#P&Z#P#Q&Z#Q#R&Z#R#S&Z#S#T&Z#T#o&Z#o#p&Z#q#r&Z#r#s&Z$gBn&ZBoD`&ZDfDg,}DgDh,}DhDi,}DiDj,}DjDk,}DkDl,}DlDm,}DmDn,}DnDo,}DoDp,}DvGl&ZGlGm,}GmGn,}GnGo,}GoGp,}GpGq,}GqGr,}GrGs,}GsGt,}GtGu,}GuGv,}Gv&FV&Z&FW;'S&Z;'S;=`*b<%l?Hb&Z?HcO&Z~1]O!|~~1bO#T~~1gO#V~~1lO#U~",
  tokenizers: [0],
  topRules: {"Program":[0,25]},
  dynamicPrecedences: {"57":-10},
  specialized: [{term: 28, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 28, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 924
})
}
